import { JobWithCompanyAndOwner } from "@cx/feathers-client";
import { EntitySpinner } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "~/src/components/PageTitle";
import { AdminJobForm } from "~/src/feature/Job/AdminJobForm";
import { selectRegion } from "~/src/state/slices/region-slice";

export const JobEdit: FC = () => {
  const region = useSelector(selectRegion);
  const { data, isLoading } = useEntityHydrator("jobs", {
    $populate: ["company", "owner"],
  });

  if (isLoading || !data) {
    return <EntitySpinner />;
  }

  const job = data as unknown as JobWithCompanyAndOwner;

  return (
    <>
      <PageTitle type="edit" />
      <AdminJobForm job={job} region={region} />
    </>
  );
};
