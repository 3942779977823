import { RouteFactory } from "@cx/ui-common";
import { PartnerCreate } from "./create";
import { PartnerEdit } from "./edit";
import { PartnerList } from "./list";

export const PartnerRoutes = RouteFactory(
  "partners",
  PartnerList,
  PartnerEdit,
  PartnerCreate,
);
