import { User } from "@cx/feathers-client";
import { AsyncDropdown } from "@cx/ui-form";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectRegion } from "~/src/state/slices/region-slice";

type Props = {
  user?: User;
};

export const UserDropdown: FC<Props> = ({ user }) => {
  const region = useSelector(selectRegion);

  return (
    <AsyncDropdown
      key={`user-${region}`}
      field="user"
      title="User"
      service="users"
      getLabel={({ firstName, lastName, email }) =>
        `${firstName ?? ""} ${lastName ?? ""} (${email})`
      }
      defaultValue={user}
      query={($search) => ({ $search, region })}
      required
    />
  );
};
