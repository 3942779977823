import { Heading, Text } from "@chakra-ui/react";
import { MirrorLayout } from "@cx/ui-features";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { LoginForm } from "./LoginForm";

export const Login: FC = () => {
  return (
    <>
      <Helmet title="Login" />

      <MirrorLayout
        logoAria="Redirect to login"
        bgAria="Login background image"
        company="CandidateX"
      >
        <Heading size={{ sm: "lg", xl: "xl" }} mb="2">
          Login
        </Heading>
        <Text fontSize={{ sm: "md", xl: "lg" }} mb="4">
          Welcome back! Please enter your details.
        </Text>
        <LoginForm />
      </MirrorLayout>
    </>
  );
};
