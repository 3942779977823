import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { UserTable } from "~/src/feature/User/UserTable";

export const UserList: FC = () => {
  return (
    <>
      <PageTitle type="list" buttonLabel="Add Admin" />
      <UserTable />
    </>
  );
};
