import { Phrases } from "@cx/react-polyglot";
import { FormProviderProps } from "@cx/ui-form";

export const formConfig: Omit<FormProviderProps<Phrases>, "children"> = {
  googlePlaces: {
    apiKey: globalThis.config.VITE_MAPS_API_KEY,
    autocompletionRequest: {
      types: ["(cities)"],
    },
    defaultError: "common.location.invalid",
  },
  dropdown: {
    placeholder: ["common.select", "common.selectAll"],
    styles: {
      dropdownIndicator: (provided) => ({
        ...provided,
        bg: "transparent",
        px: 2,
        cursor: "inherit",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
    },
    selectedOptionStyle: "check",
  },
  submitButton: {
    labels: {
      cancel: "common.cancel",
      save: "common.save",
    },
  },
};
