import { createMigrate, PersistedState } from "redux-persist";

type InitialState = Partial<{
  _persist: unknown;
}>;

const resetState = (state: PersistedState) => {
  const oldState: InitialState = {
    ...state,
  };

  return {
    _persist: oldState._persist,
  } as PersistedState;
};

export const migrations = createMigrate(
  {
    0: resetState,
    1: resetState,
  },
  { debug: globalThis.config.DEV },
);
