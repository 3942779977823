import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { JobTable } from "~/src/feature/Job/JobTable";

export const JobList: FC = () => {
  return (
    <>
      <PageTitle type="list" />
      <JobTable />
    </>
  );
};
