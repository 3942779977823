import { toDateString } from "@cx/format";
import { UnknownObject } from "@cx/types";
import { DateFilter, TooltipCell } from "@cx/ui-common";
import {
  ActionPanelCell,
  DataTable,
  EmptyState,
  ResourceColumn,
  useActionPanel,
} from "@cx/ui-data-table";
import {
  CandidateLink,
  JobLink,
  PopulatedJobApplication,
} from "@cx/ui-features";
import { FC, useMemo } from "react";
import { useRegionHydrator } from "../RegionSelector";

type Props = {
  query?: UnknownObject;
};

export const JobApplicationTable: FC<Props> = ({ query = {} }) => {
  const resource = "job-applications";
  const { data, invalidate, ...hydratorProps } = useRegionHydrator(resource, {
    $populate: ["job"],
    ...query,
  });
  const { deleteEntry, Panel } = useActionPanel(resource, invalidate);

  const columns = useMemo(
    (): ResourceColumn<PopulatedJobApplication[]> => [
      {
        Header: "Job",
        accessor: "job",
        Cell: JobLink,
        disableSortBy: true,
      },
      {
        Header: "Candidate",
        accessor({ candidate, firstName, lastName }) {
          return { _id: candidate, firstName, lastName };
        },
        Cell: CandidateLink,
        disableSortBy: true,
      },
      {
        Header: "Applied on",
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return (
            <TooltipCell value={createdAt}>
              {toDateString(createdAt)}
            </TooltipCell>
          );
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return <ActionPanelCell onClick={() => deleteEntry(id)} link={id} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource="job applications" />}
        columns={columns}
        data={data as unknown as PopulatedJobApplication[]}
        {...hydratorProps}
        initialState={{
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        isSearchable
      >
        {(instance) => <DateFilter instance={instance} />}
      </DataTable>
      {Panel}
    </>
  );
};
