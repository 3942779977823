import { EntitySpinner } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { WebhookEntity } from "~/src/feature/Webhook/WebhookEntity";

export const WebhookView: FC = () => {
  const { data, isLoading } = useEntityHydrator("partner-webhook");

  if (isLoading || !data) {
    return <EntitySpinner />;
  }

  return (
    <>
      <PageTitle type="view" />
      <WebhookEntity data={data} />
    </>
  );
};
