import { authReducer, opts, rootReducerFactory } from "@cx/ui-state";
import { combineReducers } from "redux";
import { app } from "~/lib/feathers";
import { default as regionReducer } from "./region-slice";

const combinedReducers = combineReducers({
  auth: authReducer,
  region: regionReducer,
});

export const rootReducer = rootReducerFactory(combinedReducers);
opts.set("app", app);

type _RootState = ReturnType<typeof combinedReducers>;

declare module "@cx/ui-state" {
  export interface RootState extends _RootState {}
}
