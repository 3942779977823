import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { VscRefresh } from "react-icons/vsc";

function base64(buffer: number[]) {
  return btoa(String.fromCharCode.apply(null, buffer));
}

function randomToken(length = 48) {
  const tokenArray = new Uint8Array(length);
  crypto.getRandomValues(tokenArray);

  return base64([...tokenArray]);
}

type Props = {
  field: string;
};

export const GenerateTokenButton: FC<Props> = ({ field }) => {
  const { setValue } = useFormContext();

  function genToken() {
    setValue(field, randomToken());
  }

  return (
    <Tooltip hasArrow label="Generate token" placement="top">
      <IconButton
        aria-label="Generate token"
        icon={<Icon as={VscRefresh} fontSize="xl" />}
        variant="ghost"
        onClick={genToken}
      ></IconButton>
    </Tooltip>
  );
};
