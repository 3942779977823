import { DataTableHydrator, useDataTableHydrator } from "@cx/ui-data-table";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { selectRegion } from "~/src/state/slices/region-slice";

export const useRegionHydrator: DataTableHydrator = (
  serviceName,
  query = {},
) => {
  const region = useSelector(selectRegion);
  const hydratorProps = useDataTableHydrator(serviceName, { ...query, region });

  useUpdateEffect(() => {
    hydratorProps.invalidate(region);
  }, [region]);

  return hydratorProps;
};
