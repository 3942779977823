import { Center, Spinner } from "@chakra-ui/react";
import { Candidate, Diversity } from "@cx/feathers-client";
import { Modify } from "@cx/types";
import { useEntityHydrator } from "@cx/ui-hooks";
import { TabLinks } from "@cx/ui-tab-links";
import { FC } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { PageTitle } from "~/src/components/PageTitle";

type CandidateDiversity = Candidate["diversity"];

type CandidateWithDiversity = Modify<
  Candidate,
  {
    compensation?: Candidate["compensation"];
    diversity: {
      [K in keyof CandidateDiversity]: CandidateDiversity[K] extends Array<any>
        ? Diversity[]
        : Diversity;
    };
  }
>;

type CandidateContext = {
  candidate: CandidateWithDiversity;
};

export function useCandidateContext() {
  return useOutletContext<CandidateContext>();
}

export const CandidateViewLayout: FC = () => {
  const { data: candidate, isLoading } = useEntityHydrator("candidates", {
    $populate: "diversity",
  });

  if (isLoading || !candidate) {
    return (
      <Center minH="96">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <PageTitle type="view" />

      {/* navigation */}
      <TabLinks
        items={[
          {
            name: "Summary",
          },
          {
            name: "Applications",
            path: "applications",
          },
          {
            name: "Diversity",
            path: "diversity",
          },
        ]}
      />

      {/* children */}
      <Outlet context={{ candidate }} />
    </>
  );
};
