import { FC } from "react";
import { useCandidateContext } from "~/src/feature/Candidate/CandidateViewLayout";
import { JobApplicationTable } from "../JobApplication/JobApplicationTable";

export const CandidateApplications: FC = () => {
  const { candidate } = useCandidateContext();

  return (
    <JobApplicationTable
      query={{
        candidate: candidate._id,
      }}
    />
  );
};
