import { User } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { FormCheckbox, FormRow } from "@cx/ui-form";
import { FC } from "react";
import { UserDropdown } from "../User";

type Props = {
  user?: User;
};

export const RecruiterExistingUser: FC<Props> = ({ user }) => {
  const { t } = useTranslation("recruiters");

  return (
    <FormRow direction="column">
      <UserDropdown user={user} />
      <FormCheckbox field="verified" variant="compressed">
        {t("verified.existing")}
      </FormCheckbox>
    </FormRow>
  );
};
