import { RouteFactory } from "@cx/ui-common";
import { JobApplicationEdit } from "./edit";
import { JobApplicationList } from "./list";

export const JobApplicationRoutes = RouteFactory(
  "applications",
  JobApplicationList,
  JobApplicationEdit,
  null,
);
