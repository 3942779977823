import { toDateString } from "@cx/format";
import { TooltipCell } from "@cx/ui-common";
import { DataTable, EmptyState, ResourceColumn } from "@cx/ui-data-table";
import {
  CandidateLink,
  JobLink,
  PopulatedJobApplication,
} from "@cx/ui-features";
import { FC, useMemo } from "react";
import { useRegionHydrator } from "../RegionSelector";

export const JobApplicationSummaryTable: FC = () => {
  const resource = "job-applications";
  const { data, invalidate, ...hydratorProps } = useRegionHydrator(resource, {
    $populate: ["candidate", "job"],
    $limit: 5,
  });

  const columns = useMemo(
    (): ResourceColumn<PopulatedJobApplication[]> => [
      {
        Header: "Candidate",
        accessor: "candidate",
        Cell: CandidateLink,
        disableSortBy: true,
      },
      {
        Header: "Job",
        accessor: "job",
        Cell: JobLink,
        disableSortBy: true,
      },
      {
        Header: "Timestamp",
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return (
            <TooltipCell value={createdAt}>
              {toDateString(createdAt)}
            </TooltipCell>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <DataTable
      EmptyState={<EmptyState resource="job applications" />}
      columns={columns}
      data={data as unknown as PopulatedJobApplication[]}
      initialState={{
        pageIndex: 0,
        sortBy: [{ id: "createdAt", desc: true }],
      }}
      {...hydratorProps}
      isPaginated={false}
      showTotal={false}
    />
  );
};
