import { chakra } from "@chakra-ui/react";
import { Partner } from "@cx/feathers-client";
import { valuesToOptions } from "@cx/format";
import { Partners } from "@cx/types";
import { DividerText } from "@cx/ui-common";
import {
  Dropdown,
  FormInput,
  FormRow,
  FormSubmitButtons,
  PasswordInput,
} from "@cx/ui-form";
import { useOnSubmit } from "@cx/ui-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { GenerateTokenButton } from "./GenerateTokenButton";
import { PartnerSchema } from "./partner-schema";

type Props = {
  data?: Partner;
};

export const PartnerForm: FC<Props> = ({ data }) => {
  const save = useOnSubmit("partners", data?._id);
  const [original, setOriginal] = useState(data);

  const methods = useForm<PartnerSchema>({
    resolver: yupResolver(PartnerSchema, { stripUnknown: true }),
    defaultValues: data,
    mode: "all",
  });

  async function onSubmit(value: PartnerSchema) {
    const payload: Partial<Partner> = {
      ...value,
    };

    if (value.token.length && value.token === original?.token) {
      delete payload.token;
    }

    const partner = await save(payload);
    setOriginal(partner);
  }

  return (
    <FormProvider {...methods}>
      <chakra.form
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="false"
        noValidate
      >
        <FormRow>
          <FormInput
            field="name"
            title="Name"
            autoComplete="new-name"
            required
          />
          <Dropdown
            field="pid"
            title="Partner ID"
            options={valuesToOptions(Partners, false)}
            isDisabled={!!data?.pid}
          />
        </FormRow>

        <DividerText>API Config</DividerText>
        <FormRow>
          <FormInput
            field="api.url"
            title="URL"
            required
            autoComplete="one-time-code"
          />
        </FormRow>

        <FormRow>
          <PasswordInput
            field="api.key"
            title="Partner Key"
            autoComplete="one-time-code"
          />
        </FormRow>

        <DividerText>Auth</DividerText>

        <FormRow align="center">
          <PasswordInput
            field="token"
            title="CX Token"
            autoComplete="one-time-code"
          />

          <GenerateTokenButton field="token" />
        </FormRow>

        {/* Buttons */}
        <FormSubmitButtons to="/partners" />
      </chakra.form>
    </FormProvider>
  );
};
