import { Partners } from "@cx/types";
import { InferType, mixed, object, string } from "yup";

export const PartnerSchema = object({
  name: string().required(),
  pid: mixed<Partners>()
    .required()
    .oneOf([...Partners]),
  api: object({
    url: string().required(),
    key: string().required(),
  }),
  token: string().required(),
});

export type PartnerSchema = InferType<typeof PartnerSchema>;
