import { capitalize } from "@cx/format";
import { PageTitle as BasePageTitle } from "@cx/ui-common";
import { plural, singular } from "pluralize";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { CreateButton } from "./CreateButton";
import { getNavbarItem } from "./navbar-items";

type PageTitleProps = {
  type: "list" | "edit" | "create" | "view";
  children?: Iterable<string> | string;
  showCreate?: boolean;
  buttonLabel?: string;
};

function useAutoTitle(
  type: PageTitleProps["type"],
  override: string | Iterable<string> = "",
) {
  const { pathname } = useLocation();
  const section = getNavbarItem(pathname);

  const isList = type === "list";

  return !section || override
    ? Array.from(override).join("")
    : isList
    ? plural(section.name)
    : [capitalize(type), singular(section.name)].join(" ");
}

export const PageTitle: FC<PageTitleProps> = ({
  type,
  children,
  showCreate = true,
  buttonLabel,
}) => {
  const title = useAutoTitle(type, children);

  return (
    <BasePageTitle title={title}>
      {type === "list" && showCreate && (
        <CreateButton>{buttonLabel}</CreateButton>
      )}
    </BasePageTitle>
  );
};
