import { Box, Button, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export const NotFound: FC = () => {
  return (
    <>
      <Helmet title="Not Found" />

      <Center h="container.md">
        <Stack direction={["column", "row"]} align="start" spacing="6">
          <Heading size="4xl" mx="auto">
            404
          </Heading>

          <Box textAlign={["center", "initial"]}>
            <Box
              borderLeftColor="lightgrey"
              borderLeftWidth="thin"
              pl={{
                md: "3",
              }}
            >
              <Heading>Page not found</Heading>
              <Text>Sorry, we couldn't find the page you're looking for.</Text>
            </Box>

            <Button
              as={Link}
              to="/dashboard"
              mt="10"
              ml={{
                md: "3",
              }}
            >
              Go back home
            </Button>
          </Box>
        </Stack>
      </Center>
    </>
  );
};
