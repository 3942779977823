import { EntitySpinner } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { PopulatedRecruiter, RecruiterForm } from "~/src/feature/Recruiter";

export const RecruiterEdit: FC = () => {
  const { data, isLoading } = useEntityHydrator("recruiters", {
    $populate: ["company", "user"],
  });

  if (isLoading) {
    return <EntitySpinner />;
  }

  if (!data?.company) {
    (data as any).company = {};
  }

  return (
    <>
      <PageTitle type="edit" />
      <RecruiterForm data={data as unknown as PopulatedRecruiter} />
    </>
  );
};
