import { Regions } from "@cx/region-config";
import { RootState } from "@cx/ui-state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type RegionState = {
  current: Regions;
};

const slice = createSlice({
  name: "region",
  initialState: {
    current: navigator.languages.includes("en-GB") ? "uk" : "na",
  } as RegionState,
  reducers: {
    setRegion: (state, { payload }: PayloadAction<Regions>) => {
      state.current = payload;
    },
  },
});

export const { setRegion } = slice.actions;

export const selectRegion = ({ region }: RootState) => region.current;

export default slice.reducer;
