import { Icon } from "@chakra-ui/react";
import { toDateString } from "@cx/format";
import { DateFilter, TooltipCell } from "@cx/ui-common";
import {
  ActionPanelCell,
  DataTable,
  EmptyState,
  ResourceColumn,
  useActionPanel,
} from "@cx/ui-data-table";
import { CompanyLink, UserLink } from "@cx/ui-features";
import { FC, useMemo } from "react";
import { FiCheckSquare, FiXSquare } from "react-icons/fi";
import { useRegionHydrator } from "../RegionSelector";

export const RecruiterTable: FC = () => {
  const resource = "recruiters";
  const { data, invalidate, ...hydratorProps } = useRegionHydrator(resource, {
    $populate: "company",
  });

  const { deleteEntry, Panel } = useActionPanel(resource, invalidate);

  const columns = useMemo(
    (): ResourceColumn<typeof data> => [
      {
        Header: "User",
        id: "firstName,lastName",
        accessor: UserLink,
      },
      {
        Header: "Company",
        accessor: "company",
        Cell(content) {
          return content.value ? <CompanyLink {...content} /> : null;
        },
        disableSortBy: true,
      },
      {
        Header: "Verified",
        accessor: "verified",
        Cell({ value }) {
          const icon = value ? FiCheckSquare : FiXSquare;
          const color = value ? "inherit" : "red";

          return <Icon as={icon} color={color} />;
        },
      },
      {
        Header: "Timestamp",
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return (
            <TooltipCell value={createdAt}>
              {toDateString(createdAt)}
            </TooltipCell>
          );
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return <ActionPanelCell onClick={() => deleteEntry(id)} link={id} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource={resource} />}
        columns={columns}
        data={data}
        {...hydratorProps}
        isSearchable
        initialState={{
          sortBy: [{ id: "createdAt", desc: true }],
        }}
      >
        {(instance) => <DateFilter instance={instance} />}
      </DataTable>
      {Panel}
    </>
  );
};
