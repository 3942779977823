import { RouteFactory } from "@cx/ui-common";
import { WebhookView } from "./view";
import { WebhookList } from "./list";

export const WebhookRoutes = RouteFactory(
  "webhooks",
  WebhookList,
  WebhookView,
  null,
);
