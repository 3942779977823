import { RegionConfigProvider } from "@cx/react-region-config";
import { FC, ReactNode } from "react";
import { useDefaultOption } from "./use-default-option";

export const SelectedRegionConfigProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { value: region } = useDefaultOption();

  return (
    <RegionConfigProvider region={region}>{children}</RegionConfigProvider>
  );
};
