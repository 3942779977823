import { FC } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "~/src/components/PageTitle";
import { AdminJobForm } from "~/src/feature/Job/AdminJobForm";
import { selectRegion } from "~/src/state/slices/region-slice";

export const JobCreate: FC = () => {
  const region = useSelector(selectRegion);

  return (
    <>
      <PageTitle type="create" />
      <AdminJobForm region={region} />
    </>
  );
};
