// root stuff
import { ChakraProvider } from "@chakra-ui/react";
import { FeathersProvider } from "@cx/feathers-client";
import { TranslationProvider } from "@cx/react-polyglot";
import { FormProvider } from "@cx/ui-form";
import { FC, ReactNode, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { app } from "~/lib/feathers";
import { formConfig } from "~/lib/form-config";
import { persistor, store } from "~/src/state";
import theme from "../theme";
import { SelectedRegionConfigProvider } from "./feature/RegionSelector";
import { Routes } from "./routes";
import config from "./seo";
import "./utils/sentry";

const Root: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Helmet {...config} />
        <ChakraProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <TranslationProvider
              locale="en-gb"
              server={globalThis.config.VITE_L10N_HOST}
              app="admin"
            >
              <FeathersProvider app={app}>
                <FormProvider<string> {...formConfig}>
                  <SelectedRegionConfigProvider>
                    <StrictMode>{children}</StrictMode>
                  </SelectedRegionConfigProvider>
                </FormProvider>
              </FeathersProvider>
            </TranslationProvider>
          </PersistGate>
        </ChakraProvider>
      </HelmetProvider>
    </Provider>
  );
};

const root = createRoot(document.getElementById("root")!);

root.render(
  <Root>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Root>,
);
