import { EntitySpinner } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import {
  CompanyFormWithPartner,
  CompanyWithPartner,
} from "~/src/feature/Company";

export const CompanyEdit: FC = () => {
  const { data, isLoading } = useEntityHydrator("companies", {
    $populate: "partner.ref",
  });

  if (isLoading || !data) {
    return <EntitySpinner />;
  }

  const company = data as unknown as CompanyWithPartner;

  return (
    <>
      <PageTitle type="edit" />
      <CompanyFormWithPartner data={company} />
    </>
  );
};
