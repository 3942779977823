import { chakraComponents } from "chakra-react-select";
import { GroupBase, SingleValueProps } from "react-select";

export const SingleValueWithPlaceholder = <
  T,
  Multi extends boolean,
  Group extends GroupBase<T>,
>({
  children,
  ...props
}: SingleValueProps<T, Multi, Group>) => {
  return (
    <chakraComponents.SingleValue {...props}>
      {props.selectProps.placeholder}: {children}
    </chakraComponents.SingleValue>
  );
};
