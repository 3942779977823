import { Partner, useFeathersService } from "@cx/feathers-client";
import { TableInstanceComponent } from "@cx/ui-data-table";
import { useDropdownProps, useOptionLoader } from "@cx/ui-form";
import { Paginated } from "@feathersjs/feathers";
import { AsyncSelect } from "chakra-react-select";
import { PlaceholderWithAll } from "./PlaceholderWithAll";
import { SingleValueWithPlaceholder } from "./SingleValueWithPlaceHolder";

export const PartnerFilter: TableInstanceComponent = ({
  instance: { setFilter },
}) => {
  const { styles } = useDropdownProps();
  const service = useFeathersService("partners");

  const search = async (inputValue: string) => {
    const { data } = (await service.find({
      query: {
        name: { $search: inputValue },
        $limit: 50,
        $sort: {
          name: 1,
        },
      },
    })) as Paginated<Partner>;

    return data;
  };

  const optionLoader = useOptionLoader(search, 350);

  return (
    <AsyncSelect<Partner>
      placeholder="Partner"
      aria-label="Partner"
      onChange={(partner) => {
        const value = partner === null ? undefined : partner._id;
        setFilter("partner", value);
      }}
      loadOptions={optionLoader}
      chakraStyles={{
        ...styles,
        container: (provided) => ({
          ...provided,
          width: "48",
        }),
      }}
      components={{
        SingleValue: SingleValueWithPlaceholder,
        Placeholder: PlaceholderWithAll,
      }}
      getOptionLabel={(partner) => partner.name}
      getOptionValue={(partner) => partner._id}
      defaultOptions
      inputId="partner-name"
      variant="unstyled"
      size="sm"
      isClearable
    />
  );
};
