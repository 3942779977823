import { DiversityTypes } from "@cx/feathers-client";
import { valuesToOptions } from "@cx/format";
import { TableInstanceComponent } from "@cx/ui-data-table";
import { useDropdownProps } from "@cx/ui-form";
import { Select } from "chakra-react-select";

export const CategoryFilter: TableInstanceComponent = ({
  instance: { setFilter },
}) => {
  const options = valuesToOptions(DiversityTypes, false);
  const { styles } = useDropdownProps();

  return (
    <Select
      placeholder="Select category"
      options={options}
      onChange={(opt) => {
        const value = opt === null ? undefined : opt.value;

        setFilter("type", value);
      }}
      chakraStyles={{
        ...styles,
        container: (provided) => ({
          ...provided,
          width: "56",
        }),
      }}
      isClearable
    />
  );
};
