import {
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";
import { currencyFormatter } from "@cx/format";
import { FC } from "react";
import { useCandidateContext } from "~/src/feature/Candidate/CandidateViewLayout";

export const CandidateSummary: FC = () => {
  const { candidate } = useCandidateContext();

  return (
    <TableContainer>
      <Table
        sx={{
          "td:first-of-type": {
            fontWeight: "bold",
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Label</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Name</Td>
            <Td>
              {candidate.firstName} {candidate.lastName}
            </Td>
          </Tr>
          <Tr>
            <Td>Contact Number</Td>
            <Td>{candidate.phone}</Td>
          </Tr>
          <Tr>
            <Td>Location</Td>
            <Td>{candidate.location?.formatted}</Td>
          </Tr>
          <Tr>
            <Td>Location Preference</Td>
            <Td>{candidate.locationPreference.join(", ")}</Td>
          </Tr>
          <Tr>
            <Td>Created At</Td>
            <Td>{candidate.createdAt.substring(0, 10)}</Td>
          </Tr>
          <Tr>
            <Td>Work Type</Td>
            <Td>{candidate.workTypes.join(", ")}</Td>
          </Tr>
          <Tr>
            <Td>Compensation</Td>
            <Td>
              <UnorderedList>
                {Object.entries(candidate.compensation || {}).map(
                  ([type, amount]) => {
                    return (
                      <ListItem key={`comp-${type}`}>
                        {[
                          type,
                          currencyFormatter(amount, {
                            currency: candidate.currency,
                          }),
                        ].join(" - ")}
                      </ListItem>
                    );
                  },
                )}
              </UnorderedList>
            </Td>
          </Tr>
          <Tr>
            <Td>Availability</Td>
            <Td>{candidate.availableAt?.substring(0, 10)}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
