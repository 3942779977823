import { valuesToOptions } from "@cx/format";
import { TableInstanceComponent } from "@cx/ui-data-table";
import { useDropdownProps } from "@cx/ui-form";
import { Select } from "chakra-react-select";
import { PlaceholderWithAll } from "./PlaceholderWithAll";
import { SingleValueWithPlaceholder } from "./SingleValueWithPlaceHolder";

const errorCodes = [201, 400, 401, 403, 404].map((num) => String(num));
const options = valuesToOptions(errorCodes, false);

export const HttpStatusFilter: TableInstanceComponent = ({
  instance: { setFilter },
}) => {
  const { styles } = useDropdownProps();

  return (
    <Select
      placeholder="Status"
      options={options}
      onChange={(opt) => {
        const value = opt === null ? undefined : opt.value;
        setFilter("status", value);
      }}
      chakraStyles={styles}
      components={{
        SingleValue: SingleValueWithPlaceholder,
        Placeholder: PlaceholderWithAll,
      }}
      variant="unstyled"
      size="sm"
      isClearable
    />
  );
};
