import { Button, Stack } from "@chakra-ui/react";
import { FormInput } from "@cx/ui-form";
import { useToastStatus } from "@cx/ui-hooks";
import { authenticate, clear, useAppDispatch } from "@cx/ui-state";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoginSchema } from "./schema";

export const LoginForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToastStatus();

  const methods = useForm<LoginSchema>({
    resolver: yupResolver(LoginSchema),
  });

  const login = async (credentials: LoginSchema) => {
    try {
      dispatch(clear());
      await dispatch(authenticate(credentials)).unwrap();

      toast.success("Login successful!");
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      toast.error("Invalid username/password", {
        duration: 2500,
      });
    }
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(login)}>
        <Stack spacing="5" mt="3">
          <FormInput
            field="email"
            title="Email"
            placeholder="Email"
            autoComplete="email"
          />

          <FormInput
            field="password"
            title="Password"
            type="password"
            placeholder="****************"
            autoComplete="current-password"
          />
        </Stack>

        <Button isLoading={isSubmitting} type="submit" w="full" mt="10">
          Sign In
        </Button>
      </form>
    </FormProvider>
  );
};
