import { Button } from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  children?: string;
};

export const CreateButton: FC<Props> = ({ children = "Create" }) => {
  return (
    <Button as={Link} variant="outline" to="create">
      {children}
    </Button>
  );
};
