import { RouteFactory } from "@cx/ui-common";
import { CompanyCreate } from "./create";
import { CompanyEdit } from "./edit";
import { CompanyList } from "./list";

export const CompanyRoutes = RouteFactory(
  "companies",
  CompanyList,
  CompanyEdit,
  CompanyCreate,
);
