import { extendTheme } from "@chakra-ui/react";
import { theme as cxTheme } from "@cx/ui-theme";

const {
  components: { Editor, Input, Link, Table, Tabs },
  config,
  fonts,
  colors,
  styles,
} = cxTheme;

const theme = extendTheme({
  config,
  fonts,
  colors,
  styles,
  components: {
    Editor,
    Input,
    Link,
    Table,
    Tabs,
  },
});

export default theme;
