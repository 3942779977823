import { HStack } from "@chakra-ui/react";
import { TruncatedCell } from "@cx/ui-common";
import {
  ActionPanelCell,
  DataTable,
  EmptyState,
  ResourceColumn,
  useActionPanel,
} from "@cx/ui-data-table";
import { FC, useMemo } from "react";
import { useRegionHydrator } from "../RegionSelector";
import { CategoryFilter } from "./CategoryFilter";

export const DiversityTable: FC = () => {
  const resource = "diversities";
  const { data, invalidate, ...hydratorProps } = useRegionHydrator(resource);
  const { deleteEntry, Panel } = useActionPanel(resource, invalidate);

  const columns = useMemo(
    (): ResourceColumn<typeof data> => [
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Label",
        accessor: "label",
        Cell({ value: label }) {
          return <TruncatedCell value={label} />;
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return <ActionPanelCell onClick={() => deleteEntry(id)} link={id} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource={resource} />}
        columns={columns}
        data={data}
        {...hydratorProps}
        searchPlaceholder="Search label"
        isSearchable
      >
        {(instance) => (
          <HStack>
            <CategoryFilter instance={instance} />
          </HStack>
        )}
      </DataTable>
      {Panel}
    </>
  );
};
