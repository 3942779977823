import { Heading, SimpleGrid } from "@chakra-ui/react";
import { RecordCount, SummaryCard, Welcome } from "@cx/ui-common";
import { selectUser } from "@cx/ui-state";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { QueueStatusList } from "../feature/Dashboard";
import { JobSummaryTable } from "../feature/Job/JobSummaryTable";
import { JobApplicationSummaryTable } from "../feature/JobApplication/JobApplicationSummaryTable";
import { WebhookHistogram } from "../feature/Webhook/WebhookHistorgram";
import { selectRegion } from "../state/slices/region-slice";

export type DashboardHeadingProps = {
  children: string;
};

export const DashboardHeading: FC<DashboardHeadingProps> = ({ children }) => {
  return (
    <Heading size="md" mb="3">
      {children}
    </Heading>
  );
};

export const Dashboard: FC = () => {
  const user = useSelector(selectUser);
  const region = useSelector(selectRegion);

  const text = {
    error: "Unavailable",
    empty: "N/A",
  };

  return (
    <>
      <Helmet title="Dashboard" />
      <Welcome
        salutation={`Welcome back, ${user.firstName}`}
        subHeading="Summary and recent activity"
      />

      <DashboardHeading>Queue Status</DashboardHeading>
      <QueueStatusList mb="6" />

      <DashboardHeading>Webhooks</DashboardHeading>
      <WebhookHistogram mb="6" />

      <DashboardHeading>Metrics</DashboardHeading>
      <SimpleGrid columns={[1, 3]} gap={["5", "6"]} mb="6">
        <RecordCount
          service="jobs"
          label="Posted Jobs"
          text={text}
          query={{ region }}
        />
        <RecordCount
          label="Applications"
          service="job-applications"
          text={text}
          to="/applications"
          query={{ region }}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} gap={["5", "6"]}>
        <SummaryCard label="Latest Jobs">
          <JobSummaryTable />
        </SummaryCard>

        <SummaryCard label="Latest Applications">
          <JobApplicationSummaryTable />
        </SummaryCard>
      </SimpleGrid>
    </>
  );
};
