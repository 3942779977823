import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { RecruiterTable } from "~/src/feature/Recruiter/RecruiterTable";

export const RecruiterList: FC = () => {
  return (
    <>
      <PageTitle type="list" />
      <RecruiterTable />
    </>
  );
};
