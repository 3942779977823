import { chakra, HStack, Radio, RadioGroup } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { DividerText } from "@cx/ui-common";
import { FormRow, FormSubmitButtons } from "@cx/ui-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useState } from "react";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { CompanyDropdown } from "../Company";
import {
  NewOrExistingRecruiterSchema,
  RecruiterSchema,
} from "./recruiter-schema";
import { RecruiterExistingUser } from "./RecruiterExistingUser";
import { RecruiterNewUser } from "./RecruiterNewUser";
import { PopulatedRecruiter } from "./types";
import { useSaveRecruiter } from "./use-save-recruiter";

type Props = {
  data?: PopulatedRecruiter;
};

type RecruiterUserType = "new" | "existing";

export const RecruiterForm: FC<Props> = ({ data }) => {
  const save = useSaveRecruiter(data?._id);

  const { t } = useTranslation("recruiters", "forms.user");
  const [userType, setUserType] = useState<RecruiterUserType>(
    data ? "existing" : "new",
  );

  const methods = useForm<NewOrExistingRecruiterSchema>({
    resolver: yupResolver(RecruiterSchema, {
      stripUnknown: true,
    }) as unknown as Resolver<NewOrExistingRecruiterSchema>,
    defaultValues: {
      ...(data ?? {}),
      company: data?.company._id,
      user: data?.user._id,
    },
    context: {
      userType,
    },
    mode: "all",
  });

  const UserForm =
    userType === "existing" ? (
      <RecruiterExistingUser user={data?.user} />
    ) : (
      <RecruiterNewUser />
    );

  return (
    <FormProvider {...methods}>
      <chakra.form
        onSubmit={methods.handleSubmit(save)}
        autoComplete="false"
        noValidate
      >
        <FormRow direction="column">
          <CompanyDropdown company={data?.company} />
        </FormRow>
        {/* user info */}
        <DividerText mb="6">{t("userInfo")}</DividerText>

        {/* user type selector */}
        <FormRow>
          <RadioGroup
            mx="auto"
            defaultValue={userType}
            isDisabled={!!data}
            onChange={(value: RecruiterUserType) => {
              setUserType(value);
            }}
          >
            <HStack spacing="10">
              <Radio value="new">{t("user.new")}</Radio>
              <Radio value="existing">{t("user.existing")}</Radio>
            </HStack>
          </RadioGroup>
        </FormRow>

        {UserForm}

        {/* Buttons */}
        <FormSubmitButtons to="/recruiters" />
      </chakra.form>
    </FormProvider>
  );
};
