import { Company } from "@cx/feathers-client";
import { AsyncDropdown } from "@cx/ui-form";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectRegion } from "~/src/state/slices/region-slice";

export type CompanyDropdownProps = {
  company?: Company;
};

export const CompanyDropdown: FC<CompanyDropdownProps> = ({ company }) => {
  const region = useSelector(selectRegion);

  return (
    <AsyncDropdown
      key={`company-${region}`}
      field="company"
      title="Company"
      service="companies"
      getLabel={(company) => company.name}
      defaultValue={company}
      query={($search) => ({
        $search,
        region,
        $select: ["name"],
      })}
      required
    />
  );
};
