import { Roles } from "@cx/feathers-client";
import { array, InferType, mixed, object, string } from "yup";

export const UserSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  email: string().required(),
  password: mixed<string>().transform((value) => value || undefined),
  roles: array()
    .of(
      mixed<Roles>()
        .oneOf([...Roles])
        .required(),
    )
    .required(),
});

export function UserSchemaFactory(requirePassword = false) {
  if (requirePassword) {
    return UserSchema.shape({
      password: string().required(),
    });
  }

  return UserSchema;
}

export type UserSchema = InferType<typeof UserSchema>;
