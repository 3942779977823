import { Partner } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { AsyncDropdown } from "@cx/ui-form";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectRegion } from "~/src/state/slices/region-slice";

type Props = {
  partner?: Partner;
};

export const PartnerDropdown: FC<Props> = ({ partner }) => {
  const region = useSelector(selectRegion);
  const { t } = useTranslation("forms.company.partner");

  return (
    <AsyncDropdown
      key={`partner-${region}`}
      field="partner"
      title={t("title")}
      service="partners"
      getLabel={(partner) => partner.name}
      getValue={(partner) => ({ ref: partner._id, pid: partner.pid })}
      defaultValue={partner}
      query={($search) => ({ $search, region })}
      helperText={t("helperText")}
    />
  );
};
