import { HStack, Icon, Link } from "@chakra-ui/react";
import { Partner } from "@cx/feathers-client";
import { toDateString } from "@cx/format";
import { DateFilter, TooltipCell } from "@cx/ui-common";
import {
  CellParams,
  DataTable,
  EmptyState,
  ResourceColumn,
  useDataTableHydrator,
} from "@cx/ui-data-table";
import { FC, useMemo } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";
import { HttpStatusBadge } from "./HttpStatusBadge";
import { HttpStatusFilter } from "./HttpStatusFilter";
import { PartnerFilter } from "./PartnerFilter";
import { RegionFilter } from "./RegionFilter";

export const WebhookTable: FC = () => {
  const resource = "partner-webhook";
  const { data, invalidate, ...hydratorProps } = useDataTableHydrator(
    resource,
    {
      $populate: "partner",
    },
  );

  const columns = useMemo(
    (): ResourceColumn<typeof data> => [
      {
        Header: "ID",
        id: "id",
        accessor: "_id",
        chakraProps: {
          fontFamily: "mono",
          isTruncated: true,
          w: 6,
        },
        Cell({ value }: CellParams<string>) {
          return (
            <Link as={RouterLink} to={value}>
              {value}
            </Link>
          );
        },
      },
      {
        Header: "Region",
        accessor: "region",
        chakraProps: {
          fontFamily: "mono",
          textAlign: "center",
          textTransform: "uppercase",
          w: 6,
        },
      },
      {
        Header: "Event",
        accessor: "event",
      },
      {
        Header: "Partner",
        accessor: "partner",
        Cell({ value }: CellParams<Partner | undefined>) {
          if (!value) {
            return null;
          }

          return (
            <Link as={RouterLink} to={`/partners/${value._id}`}>
              {value.name}
            </Link>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell({ value: status }) {
          return <HttpStatusBadge>{status}</HttpStatusBadge>;
        },
      },
      {
        Header: "Timestamp",
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return (
            <TooltipCell value={createdAt}>
              {toDateString(createdAt)}
            </TooltipCell>
          );
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return (
            <RouterLink to={id}>
              <Icon as={HiOutlineEye} />
            </RouterLink>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource="webhook" />}
        columns={columns}
        data={data}
        initialState={{
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        {...hydratorProps}
        isSearchable
      >
        {(instance) => (
          <HStack justify="space-between" w="65%">
            <DateFilter instance={instance} />
            <HStack justify="space-evenly">
              <PartnerFilter instance={instance} />
              <HttpStatusFilter instance={instance} />
              <RegionFilter instance={instance} />
            </HStack>
          </HStack>
        )}
      </DataTable>
    </>
  );
};
