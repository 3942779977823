import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Center,
  forwardRef,
  Spinner,
} from "@chakra-ui/react";
import { useFeathersService, WebhookMetrics } from "@cx/feathers-client";
import { waitAtLeast } from "@cx/sleep";
import { DateTime } from "luxon";
import Chart from "react-apexcharts";
import { useAsync } from "react-use";

type SeriesData = {
  x: number;
  y: number;
};

const getTimestamp = (year: number, week: number) =>
  DateTime.fromObject({ weekNumber: week, weekYear: year }).toMillis();

function formatChartData(metrics: WebhookMetrics[]) {
  const uniqueCategories = metrics.reduce(
    (collector, { year, week }) => collector.add(getTimestamp(year, week)),
    new Set<number>(),
  );

  const axis = [...uniqueCategories.values()];

  const data: Record<number | string, SeriesData[]> = {};
  const series: ApexAxisChartSeries = [];

  for (const { count, status, week, year } of metrics) {
    const timestamp = getTimestamp(year, week);
    const key = status || "unknown";

    if (!data[key]) {
      data[key] = [];
    }

    // fill in empty categories for stacked bar chart to function
    const currentLength = data[key].length;
    const startingIndex = axis.findIndex((v) => v === timestamp);
    const diff = startingIndex - currentLength;

    if (diff > 0) {
      const filler = axis
        .slice(currentLength, diff)
        .map((t) => ({ x: t, y: 0 }));
      data[key].push(...filler);
    }

    data[key].push({ x: timestamp, y: count });
  }

  for (const k in data) {
    series.push({
      name: k,
      data: data[k],
    });
  }

  return {
    axis,
    series,
  };
}

export const WebhookHistogram = forwardRef<BoxProps, "div">((props, ref) => {
  const service = useFeathersService("partner-webhook");

  const {
    loading,
    error,
    value: metrics,
  } = useAsync(() => {
    return waitAtLeast(
      () =>
        service.find({
          query: { $metrics: true },
        }) as unknown as Promise<WebhookMetrics[]>,
      250,
    );
  });

  if (loading) {
    return (
      <Center minH="7">
        <Spinner size="md" />
      </Center>
    );
  }

  if (error || !metrics) {
    return (
      <Alert ref={ref} {...props} status="warning">
        <AlertIcon />
        Unable to get webhook metrics. Please try again later
      </Alert>
    );
  }

  const { series } = formatChartData(metrics);

  const options: ApexCharts.ApexOptions = {
    chart: {
      id: "webhook-histogram",
      stacked: true,
    },
    xaxis: {
      type: "datetime",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          total: {
            enabled: false,
          },
        },
      },
    },
  };

  return (
    <Box ref={ref} {...props}>
      <Chart options={options} series={series} type="bar" height="400" />
    </Box>
  );
});
