import { useTranslation } from "@cx/react-polyglot";
import { FormInput } from "@cx/ui-form";
import { FC } from "react";
import { useWatch } from "react-hook-form";

export const ExternalIdInput: FC = () => {
  const { t } = useTranslation("forms.company.externalId");

  const ref: string = useWatch({
    name: "partner.ref",
  });

  return (
    <FormInput
      // keying to force a refresh when the parent has been nulled
      key={`extId-${ref}`}
      field={ref ? "partner.id" : "__id"}
      title={t("title")}
      placeholder={t("placeholder")}
      helperText={t("helperText")}
      required={!!ref}
      disabled={!ref}
      autoComplete="one-time-code"
    />
  );
};
