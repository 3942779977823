import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { DiversityForm } from "~/src/feature/Diversity/DiversityForm";

export const DiversityCreate: FC = () => {
  return (
    <>
      <PageTitle type="create" />
      <DiversityForm />
    </>
  );
};
