import { RouteFactory } from "@cx/ui-common";
import { IndustryCreate } from "./create";
import { IndustryEdit } from "./edit";
import { IndustryList } from "./list";

export const IndustryRoutes = RouteFactory(
  "industries",
  IndustryList,
  IndustryEdit,
  IndustryCreate,
);
