import { Box } from "@chakra-ui/react";
import { useFeathers } from "@cx/feathers-client";
import { Regions } from "@cx/region-config";
import { Option, useDropdownProps } from "@cx/ui-form";
import { useAppDispatch } from "@cx/ui-state";
import { Select } from "chakra-react-select";
import { FC, useEffect } from "react";
import { setRegion } from "~/src/state/slices/region-slice";
import { regionOptions } from "./region-options";
import { useDefaultOption } from "./use-default-option";

export const RegionSelector: FC = () => {
  const { styles } = useDropdownProps();
  const dispatch = useAppDispatch();
  const defaultOption = useDefaultOption();
  const app = useFeathers();

  useEffect(() => {
    app.set("region", defaultOption.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption.value]);

  return (
    <Box textAlign="right" mb="12" mt="6" mr="6">
      <Select<Option<Regions>>
        defaultValue={defaultOption}
        options={regionOptions}
        chakraStyles={{
          ...styles,
          container: (provided) => ({
            ...provided,
            display: "inline-flex",
            width: "56",
            cursor: "pointer",
          }),
        }}
        onChange={(option) => {
          if (option) {
            dispatch(setRegion(option.value));
          }
        }}
        isSearchable={false}
      />
    </Box>
  );
};
