import { useTranslation } from "@cx/react-polyglot";
import { FormCheckbox, FormInput, FormRow } from "@cx/ui-form";
import { FC } from "react";

export const RecruiterNewUser: FC = () => {
  const { t } = useTranslation("recruiters", "forms.user");

  return (
    <>
      <FormRow>
        <FormInput field="firstName" title={t("firstName")} required />
        <FormInput field="lastName" title={t("lastName")} required />
      </FormRow>
      <FormRow direction="column">
        <FormInput field="email" title={t("email")} type="email" required />
        <FormCheckbox field="verified" variant="compressed">
          {t("verified.new")}
        </FormCheckbox>
      </FormRow>
    </>
  );
};
