import { JobWithCompany } from "@cx/feathers-client";
import { toDateString } from "@cx/format";
import { DateFilter, TooltipCell, TruncatedCell } from "@cx/ui-common";
import {
  ActionPanelCell,
  DataTable,
  EmptyState,
  ResourceColumn,
  useActionPanel,
} from "@cx/ui-data-table";
import { CompanyLink } from "@cx/ui-features";
import { FC, useMemo } from "react";
import { useRegionHydrator } from "../RegionSelector";
import { StatusBadge } from "./StatusBadge";

export const JobTable: FC = () => {
  const resource = "jobs";
  const { data, invalidate, ...hydratorProps } = useRegionHydrator(resource, {
    $populate: "company",
  });
  const { deleteEntry, Panel } = useActionPanel(resource, invalidate);

  const columns = useMemo(
    (): ResourceColumn<JobWithCompany[]> => [
      {
        Header: "Company",
        accessor: "company",
        Cell: CompanyLink,
        disableSortBy: true,
      },
      {
        Header: "Title",
        accessor: "title",
        Cell({ value: title }) {
          return <TruncatedCell value={title} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell({ value: status }) {
          return <StatusBadge status={status} />;
        },
      },
      {
        Header: "Location",
        accessor: "location.formatted",
      },
      {
        Header: "Timestamp",
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return (
            <TooltipCell value={createdAt}>
              {toDateString(createdAt)}
            </TooltipCell>
          );
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return <ActionPanelCell onClick={() => deleteEntry(id)} link={id} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource={resource} />}
        columns={columns}
        data={data as unknown as JobWithCompany[]}
        initialState={{
          pageIndex: 0,
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        {...hydratorProps}
        isSearchable
      >
        {(instance) => <DateFilter instance={instance} />}
      </DataTable>
      {Panel}
    </>
  );
};
