import { JobWithCompanyAndOwner } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { Regions } from "@cx/region-config";
import { JobForm } from "@cx/ui-features";
import { AsyncDropdown, FormSwitchInline } from "@cx/ui-form";
import omit from "lodash.omit";
import { FC } from "react";
import { OwnerDropdown } from "./OwnerDropdown";

type Props = {
  job?: JobWithCompanyAndOwner;
  region: Regions;
};

export const AdminJobForm: FC<Props> = ({ job, region }) => {
  const { t } = useTranslation("forms.job");

  return (
    <JobForm
      data={job ? omit(job, "owner") : undefined}
      components={{
        Company: (
          <AsyncDropdown
            key={`company-${region}`}
            field="company"
            title={t("company")}
            service="companies"
            getLabel={(company) => company.name}
            defaultValue={job?.company}
            query={($search) => ({ $search, region })}
            required
          />
        ),
        Owner: <OwnerDropdown owner={job?.owner} />,
        PinJob: (
          <FormSwitchInline
            colorScheme="green"
            field="pinned"
            flexShrink="3"
            gap="1rem"
            required
            title={t("pin")}
          />
        ),
      }}
    />
  );
};
