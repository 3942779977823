import { feathersClient, handleUnauthenticated } from "@cx/feathers-client";

export const app = feathersClient(globalThis.config.VITE_API, {
  headers: {
    "x-role": "admin",
  },
});

// inject region into create options
app.hooks({
  before: {
    create: [
      ({ data, app, path }) => {
        // disable for authentication
        if (path === "auth") {
          return;
        }

        const region = app.get("region");
        let addedRegion = false;

        // handle form data
        if (data instanceof FormData) {
          if (!data.has("region")) {
            data.set("region", region);
            addedRegion = true;
          }
        }

        // handle json
        else if (!data.region) {
          data.region = region;
          addedRegion = true;
        }

        if (addedRegion) {
          console.debug(
            "[region] Added region `%s` to %s:create",
            region,
            path,
          );
        }
      },
    ],
  },
  error: {
    all: [handleUnauthenticated(401, 403)],
  },
});
