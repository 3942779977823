import { Regions } from "@cx/region-config";
import { Option } from "@cx/ui-form";

export const regionOptions: Option<Regions>[] = [
  {
    value: "na",
    label: "North America",
  },
  {
    value: "uk",
    label: "United Kingdom",
  },
];
