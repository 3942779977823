import { RouteFactory } from "@cx/ui-common";
import { Route } from "react-router-dom";
import {
  CandidateApplications,
  CandidateDiversity,
  CandidateSummary,
  CandidateViewLayout,
} from "~/src/feature/Candidate";
import { CandidateList } from "./list";

export const CandidateRoutes = (
  <>
    {RouteFactory("candidates", CandidateList, null, null)}

    <Route path="candidates/:id" element={<CandidateViewLayout />}>
      <Route index element={<CandidateSummary />} />
      <Route path="applications" element={<CandidateApplications />} />
      <Route path="diversity" element={<CandidateDiversity />} />
    </Route>
  </>
);
