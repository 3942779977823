import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FC } from "react";
import { Diversity } from "@cx/feathers-client";
import { useCandidateContext } from "~/src/feature/Candidate/CandidateViewLayout";

const diversityListToString = (diversity: Diversity[]) =>
  diversity.map(({ label }) => label).join(", ");

export const CandidateDiversity: FC = () => {
  const { candidate } = useCandidateContext();

  const {
    ethnicity,
    ageGroup,
    sexualOrientation,
    veteran,
    disability,
    mentalHealth,
    faith,
    care,
    socialClass,
    gender,
  } = candidate.diversity;

  return (
    <TableContainer>
      <Table
        sx={{
          "td:first-of-type": {
            fontWeight: "bold",
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Label</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Ethnicity</Td>
            <Td>{diversityListToString(ethnicity)}</Td>
          </Tr>
          <Tr>
            <Td>Age Group</Td>
            <Td>{ageGroup?.label}</Td>
          </Tr>
          <Tr>
            <Td>Sexual Orientation</Td>
            <Td>{sexualOrientation?.label}</Td>
          </Tr>
          <Tr>
            <Td>Veteran?</Td>
            <Td>{veteran?.label}</Td>
          </Tr>

          <Tr>
            <Td>Disability</Td>
            <Td>{diversityListToString(disability)}</Td>
          </Tr>
          <Tr>
            <Td>Mental Health</Td>
            <Td>{diversityListToString(mentalHealth)}</Td>
          </Tr>
          <Tr>
            <Td>Faith</Td>
            <Td>{faith?.label}</Td>
          </Tr>
          <Tr>
            <Td>Care Responsibilities</Td>
            <Td>{diversityListToString(care)}</Td>
          </Tr>
          <Tr>
            <Td>Social Background</Td>
            <Td>{diversityListToString(socialClass)}</Td>
          </Tr>
          <Tr>
            <Td>Gender</Td>
            <Td>{gender?.label}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
