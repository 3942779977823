import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { WebhookTable } from "~/src/feature/Webhook/WebhookTable";

export const WebhookList: FC = () => {
  return (
    <>
      <PageTitle type="list" showCreate={false} />
      <WebhookTable />
    </>
  );
};
