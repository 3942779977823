import { Company, CompanyPartnerRef, Partner } from "@cx/feathers-client";
import { Modify } from "@cx/types";
import { CompanyForm } from "@cx/ui-features";
import { FC } from "react";
import { ExternalIdInput } from "./ExternalIdInput";
import { PartnerDropdown } from "./PartnerDropdown";

export type CompanyWithPartner = Modify<
  Company,
  {
    partner?: Modify<
      CompanyPartnerRef,
      {
        ref?: Partner;
      }
    >;
  }
>;

type Props = {
  data?: CompanyWithPartner;
};

export const CompanyFormWithPartner: FC<Props> = ({ data }) => {
  let company: Company | undefined;
  let partner: Partner | undefined;

  if (data) {
    const { partner: companyPartner, ...rest } = data;

    partner = companyPartner?.ref;
    company = rest;

    if (partner) {
      company.partner = {
        id: companyPartner?.id,
        ref: partner._id,
        pid: partner.pid,
      } as CompanyPartnerRef;
    } else {
      company.partner = null;
    }
  }

  return (
    <CompanyForm
      data={company}
      components={{
        Partner: (
          <>
            <PartnerDropdown partner={partner} />
            <ExternalIdInput />
          </>
        ),
      }}
      editSlug
    />
  );
};
