import { useFeathersService, User } from "@cx/feathers-client";
import { isFeathersError } from "@cx/types";
import { useOnSubmit, useToastStatus } from "@cx/ui-hooks";
import {
  isRecruiterWithNewUser,
  NewOrExistingRecruiterSchema,
} from "./recruiter-schema";

export function useSaveRecruiter(id?: string) {
  const save = useOnSubmit("recruiters", id);
  const userService = useFeathersService("users");
  const toast = useToastStatus();

  return async function onSubmit(values: NewOrExistingRecruiterSchema) {
    if (!isRecruiterWithNewUser(values)) {
      await save(values);
      return;
    }

    try {
      const user = await userService.create({
        ...values,
        password: crypto.randomUUID() + "P",
        roles: ["recruiter"],
      } as unknown as User);

      toast.success(`Successfully created user and recruiter ${user.email}`);
    } catch (error) {
      console.error(error);

      if (isFeathersError(error)) {
        toast.error(error.message);
      } else {
        toast.error(String(error));
      }
    }
  };
}
