import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { RecruiterForm } from "~/src/feature/Recruiter/RecruiterForm";

export const RecruiterCreate: FC = () => {
  return (
    <>
      <PageTitle type="create" />
      <RecruiterForm />
    </>
  );
};
