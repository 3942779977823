import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { PartnerForm } from "~/src/feature/Partner/PartnerForm";

export const PartnerCreate: FC = () => {
  return (
    <>
      <PageTitle type="create" />
      <PartnerForm />
    </>
  );
};
