// root stuff
import { FC } from "react";
import { Route, Routes as BaseRoutes } from "react-router-dom";

// layouts
import { DashboardLayout } from "~/src/components/Layout";

// pages
import { NotFound } from "~/src/pages/404";
import { Login } from "./feature/Auth/Login";
import { CandidateRoutes } from "./pages/candidates/routes";
import { CompanyRoutes } from "./pages/companies/routes";
import { Dashboard } from "./pages/dashboard";
import { DiversityRoutes } from "./pages/diversities/routes";
import { JobApplicationRoutes } from "./pages/job-applications/routes";
import { JobRoutes } from "./pages/jobs/routes";
import { RecruiterRoutes } from "./pages/recruiters/routes";
import { UserRoutes } from "./pages/users/routes";
import { WebhookRoutes } from "./pages/webhooks/routes";
import { IndustryRoutes } from "./pages/industries/routes";
import { PartnerRoutes } from "./pages/partners/routes";

export const Routes: FC = () => {
  return (
    <BaseRoutes>
      {/* default */}
      <Route index element={<Login />} />

      {/* dashboard */}
      <Route path="/" element={<DashboardLayout />}>
        <Route path="dashboard" element={<Dashboard />} />

        {/* users */}
        {UserRoutes}

        {/* companies */}
        {CompanyRoutes}

        {/* jobs */}
        {JobRoutes}

        {/* job applications */}
        {JobApplicationRoutes}

        {/* candidates */}
        {CandidateRoutes}

        {/* diversities */}
        {DiversityRoutes}

        {/* recruiters */}
        {RecruiterRoutes}

        {/* webhooks */}
        {WebhookRoutes}

        {/* industry */}
        {IndustryRoutes}

        {/* partner */}
        {PartnerRoutes}
      </Route>

      <Route path="*" element={<NotFound />} />
    </BaseRoutes>
  );
};
