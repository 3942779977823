import { chakraComponents } from "chakra-react-select";
import { GroupBase, PlaceholderProps } from "react-select";

export const PlaceholderWithAll = <
  T,
  Multi extends boolean,
  Group extends GroupBase<T>,
>({
  children,
  ...props
}: PlaceholderProps<T, Multi, Group>) => {
  return (
    <chakraComponents.Placeholder {...props}>
      {children}: All
    </chakraComponents.Placeholder>
  );
};
