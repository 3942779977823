import { toDateString } from "@cx/format";
import { DateFilter, TooltipCell } from "@cx/ui-common";
import {
  ActionPanelCell,
  DataTable,
  EmptyState,
  ResourceColumn,
  useActionPanel,
} from "@cx/ui-data-table";
import { FC, useMemo } from "react";
import { useRegionHydrator } from "../RegionSelector";

export const CandidateTable: FC = () => {
  const resource = "candidates";
  const { data, invalidate, ...hydratorProps } = useRegionHydrator(resource);
  const { deleteEntry, Panel } = useActionPanel(resource, invalidate);

  const columns = useMemo(
    (): ResourceColumn<typeof data> => [
      {
        Header: "Name",
        id: "firstName,lastName",
        accessor: ({ firstName, lastName }) =>
          firstName ? `${firstName} ${lastName}` : "",
      },
      {
        Header: "Location",
        accessor: "location.formatted",
      },
      {
        Header: "Timestamp",
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return (
            <TooltipCell value={createdAt}>
              {toDateString(createdAt)}
            </TooltipCell>
          );
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return <ActionPanelCell onClick={() => deleteEntry(id)} link={id} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource={resource} />}
        columns={columns}
        data={data}
        initialState={{
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        {...hydratorProps}
        isSearchable
      >
        {(instance) => <DateFilter instance={instance} />}
      </DataTable>
      {Panel}
    </>
  );
};
