import { Badge, BadgeProps } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  children: number;
};

export const HttpStatusBadge: FC<Props> = ({ children: code }) => {
  let colorScheme: BadgeProps["colorScheme"];

  switch (true) {
    case code < 400:
      colorScheme = "green";
      break;

    case code < 500:
      colorScheme = "orange";
      break;

    default:
      colorScheme = "red";
      break;
  }

  return <Badge colorScheme={colorScheme}>{code}</Badge>;
};
