import { useSelector } from "react-redux";
import { selectRegion } from "~/src/state/slices/region-slice";
import { regionOptions } from "./region-options";

export function useDefaultOption() {
  const selectedRegion = useSelector(selectRegion);

  const selectedOption = regionOptions.find(
    (opt) => opt.value === selectedRegion,
  )!;

  return selectedOption;
}
