import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { CandidateTable } from "~/src/feature/Candidate";

export const CandidateList: FC = () => {
  return (
    <>
      <PageTitle type="list" showCreate={false} />
      <CandidateTable />
    </>
  );
};
