import { EntitySpinner } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { DiversityForm } from "~/src/feature/Diversity/DiversityForm";

export const DiversityEdit: FC = () => {
  const { data, isLoading } = useEntityHydrator("diversities");

  if (isLoading || !data) {
    return <EntitySpinner />;
  }

  return (
    <>
      <PageTitle type="edit" />
      <DiversityForm data={data} />
    </>
  );
};
