import { getNavbarItemsOnly, isSectionItem, MixedItem } from "@cx/ui-common";
import { FiUsers } from "react-icons/fi";
import { HiOutlineBriefcase } from "react-icons/hi";
import {
  IoBusinessOutline,
  IoFileTrayFullOutline,
  IoNewspaper,
} from "react-icons/io5";
import {
  MdAllInclusive,
  MdOutlineDashboard,
  MdPeopleOutline,
  MdIntegrationInstructions,
} from "react-icons/md";
import { TbBuildingFactory, TbWebhook } from "react-icons/tb";

const nameToPath = (name: string) => "/" + name.toLowerCase().replace(" ", "-");

const ensureItemPaths = <T extends MixedItem>(item: T) => {
  if (isSectionItem(item)) {
    item.items = item.items.map(ensureItemPaths);
    return item;
  }

  if (item.path) {
    return item;
  }

  return {
    ...item,
    path: nameToPath(item.name),
  };
};

export const navbarItems = (
  [
    { name: "Dashboard", icon: MdOutlineDashboard },
    {
      name: "Partners",
      icon: MdIntegrationInstructions,
    },

    {
      section: "Postings",
      items: [
        { name: "Jobs", icon: HiOutlineBriefcase },
        { name: "Companies", icon: IoBusinessOutline },
        { name: "Applications", icon: IoFileTrayFullOutline },
      ],
    },

    {
      section: "Accounts",
      items: [
        { name: "Users", icon: FiUsers },
        { name: "Candidates", icon: MdPeopleOutline },
        { name: "Recruiters", icon: IoNewspaper },
      ],
    },

    {
      section: "Data",
      items: [
        { name: "Diversities", icon: MdAllInclusive },
        { name: "Industries", icon: TbBuildingFactory },
      ],
    },

    {
      section: "Analytics",
      items: [{ name: "Webhooks", icon: TbWebhook }],
    },
  ] as MixedItem[]
).map(ensureItemPaths);

const navbarItemsOnly = getNavbarItemsOnly(navbarItems);

export const getNavbarItem = (path: string) =>
  navbarItemsOnly.find((item) => path.startsWith(item.path));
