import { InferType, mixed, object, string } from "yup";
import { DiversityTypes } from "@cx/feathers-client";

export const DiversitySchema = object({
  type: mixed<DiversityTypes>()
    .required()
    .oneOf([...DiversityTypes]),
  label: string().required(),
});

export type DiversitySchema = InferType<typeof DiversitySchema>;
