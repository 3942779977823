import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { UserForm } from "~/src/feature/User/UserForm";

export const UserCreate: FC = () => {
  return (
    <>
      <PageTitle type="create">Add Admin</PageTitle>
      <UserForm />
    </>
  );
};
