import { InferType, boolean, object, string } from "yup";

export const RecruiterSchema = object({
  company: string().required(),
  verified: boolean().oneOf([true]).required().default(false),
}).when("$userType", {
  is(userType: string) {
    return userType === "new";
  },
  then(schema) {
    return schema.shape({
      firstName: string().required(),
      lastName: string().required(),
      email: string().required(),
    });
  },
  otherwise(schema) {
    return schema.shape({
      user: string().required(),
    });
  },
});

export type ExistingUser = {
  user: string;
};

export type NewUser = {
  firstName: string;
  lastName: string;
  email: string;
};

type BaseSchema<T extends object = {}> = InferType<typeof RecruiterSchema> & T;

export type NewOrExistingRecruiterSchema =
  | BaseSchema<ExistingUser>
  | BaseSchema<NewUser>;

export function isRecruiterWithNewUser(
  schema: NewOrExistingRecruiterSchema,
): schema is BaseSchema<NewUser> {
  return !!(schema as BaseSchema<NewUser>).email;
}
