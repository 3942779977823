import { RouteFactory } from "@cx/ui-common";
import { DiversityCreate } from "./create";
import { DiversityEdit } from "./edit";
import { DiversityList } from "./list";

export const DiversityRoutes = RouteFactory(
  "diversities",
  DiversityList,
  DiversityEdit,
  DiversityCreate,
);
