import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { PartnerTable } from "~/src/feature/Partner/PartnerTable";

export const PartnerList: FC = () => {
  return (
    <>
      <PageTitle type="list" buttonLabel="Add">
        Partners
      </PageTitle>
      <PartnerTable />
    </>
  );
};
