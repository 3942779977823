export default {
  htmlAttributes: { lang: "en" },
  title: "CandidateX",
  titleTemplate: "%s | CX Admin",
  meta: [
    {
      name: "description",
      content: "CandidateX admin panel",
    },
  ],
};
