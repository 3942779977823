import { User } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { UnknownObject } from "@cx/types";
import { AsyncDropdown } from "@cx/ui-form";
import { FC, useCallback } from "react";
import { useWatch } from "react-hook-form";

type Props = {
  owner?: User;
};

export const OwnerDropdown: FC<Props> = ({ owner }) => {
  const { t } = useTranslation("forms.job");
  const company: string = useWatch({ name: "company" });

  const query = useCallback(
    ($search: string) => {
      const queryObj: UnknownObject = {
        roles: { $in: ["admin", "recruiter"] },
        $search,
      };

      if (company) {
        queryObj.company = company;
      }

      return queryObj;
    },
    [company],
  );

  return (
    <AsyncDropdown
      key={`owner-by-company-${company}`}
      field="owner"
      title={t("owner.title")}
      service="users"
      getLabel={({ firstName, lastName, email, roles }) => {
        const roleTag = roles?.join(", ");
        const name = [firstName, lastName].filter((v) => !!v).join(" ");

        return `[${roleTag}] ${name} (${email})`;
      }}
      defaultValue={owner}
      query={query}
      helperText={t("owner.helperText")}
      disabled={!company}
      required
    />
  );
};
