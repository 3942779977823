import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { IndustryTable } from "~/src/feature/Industry/IndustryTable";

export const IndustryList: FC = () => {
  return (
    <>
      <PageTitle type="list" />
      <IndustryTable />
    </>
  );
};
