import { Box, chakra } from "@chakra-ui/react";
import { Roles, User } from "@cx/feathers-client";
import { valuesToOptions } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import {
  Dropdown,
  FormInput,
  FormRow,
  FormSubmitButtons,
  PasswordInput,
} from "@cx/ui-form";
import { useOnSubmit } from "@cx/ui-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { InferType } from "yup";
import { UserSchemaFactory } from "./user-schema";

type Props = {
  data?: User;
};

export const UserForm: FC<Props> = ({ data }) => {
  const { t } = useTranslation("forms.user");
  const save = useOnSubmit("users", data?._id);
  const required = !data;

  const ConditionalUserSchema = UserSchemaFactory(required);
  type Schema = InferType<typeof ConditionalUserSchema>;

  const methods = useForm<Schema>({
    resolver: yupResolver(ConditionalUserSchema, { stripUnknown: true }),
    defaultValues: {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      roles: data?.roles ?? ["admin"],
      candidate: data?.candidate,
    } as unknown as Schema,
    mode: "all",
  });

  const roles = data ? Roles : (["admin"] as readonly Roles[]);

  return (
    <FormProvider {...methods}>
      <chakra.form
        onSubmit={methods.handleSubmit(save)}
        autoComplete="false"
        noValidate
      >
        <FormRow>
          <FormInput field="firstName" title={t("firstName")} required />
          <FormInput field="lastName" title={t("lastName")} required />
        </FormRow>

        <FormRow>
          <FormInput
            field="email"
            title={t("email")}
            autoComplete="none"
            required
          />
          <PasswordInput
            field="password"
            title={t("password")}
            autoComplete="new-password"
            required={required}
          />
        </FormRow>

        <FormRow>
          <Box w="full">
            <Dropdown
              field="roles"
              title={t("roles")}
              options={valuesToOptions(roles, false)}
              isMulti
              isDisabled={!data}
            />
          </Box>
        </FormRow>

        {/* Buttons */}
        <FormSubmitButtons to="/users" />
      </chakra.form>
    </FormProvider>
  );
};
