import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { JobApplicationTable } from "~/src/feature/JobApplication/JobApplicationTable";

export const JobApplicationList: FC = () => {
  return (
    <>
      <PageTitle type="list" showCreate={false} />
      <JobApplicationTable />
    </>
  );
};
