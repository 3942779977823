import { Badge, BadgeProps, Link } from "@chakra-ui/react";
import { QueueStatus } from "@cx/feathers-client";
import { useLimitedCount } from "@cx/ui-hooks";
import { FC } from "react";

type Props = QueueStatus & {
  display: string;
};

function queueLink(queueName: string, hasFailed: boolean) {
  const path = `/bullmq/queue/${queueName}`;
  const url = new URL(path, globalThis.config.VITE_API);

  if (hasFailed) {
    url.searchParams.append("status", "failed");
  }

  return url.toString();
}

export const QueueStatusBadge: FC<Props> = ({ display, name, failed }) => {
  let colorScheme: BadgeProps["colorScheme"];
  const failedCount = useLimitedCount(failed);

  switch (true) {
    case failed === 0:
      colorScheme = "green";
      break;

    case failed < 5:
      colorScheme = "orange";
      break;

    default:
      colorScheme = "red";
      break;
  }

  const badgeProps: BadgeProps = {
    fontSize: "sm",
    px: "2",
    py: "0.5",
  };

  const href = queueLink(name, failed > 0);

  return (
    <Link href={href} target="_blank">
      <Badge {...badgeProps} colorScheme={colorScheme} borderRightRadius="0">
        {display}
      </Badge>
      <Badge
        {...badgeProps}
        colorScheme={colorScheme}
        variant="solid"
        borderLeftRadius="0"
      >
        {failedCount}
      </Badge>
    </Link>
  );
};
