import { chakra } from "@chakra-ui/react";
import { Industry } from "@cx/feathers-client";
import { FormInput, FormRow, FormSubmitButtons } from "@cx/ui-form";
import { useOnSubmit } from "@cx/ui-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IndustrySchema } from "./industry-schema";

type Props = {
  data?: Industry;
};

export const IndustryForm: FC<Props> = ({ data }) => {
  const save = useOnSubmit("industries", data?._id);

  const methods = useForm<IndustrySchema>({
    resolver: yupResolver(IndustrySchema, { stripUnknown: true }),
    defaultValues: data,
    mode: "all",
  });

  return (
    <FormProvider {...methods}>
      <chakra.form
        onSubmit={methods.handleSubmit(save)}
        autoComplete="false"
        noValidate
      >
        <FormRow>
          <FormInput field="name" title="Name" required />
        </FormRow>

        {/* Buttons */}
        <FormSubmitButtons to="/industries" />
      </chakra.form>
    </FormProvider>
  );
};
