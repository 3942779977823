import {
  Alert,
  AlertIcon,
  Center,
  forwardRef,
  HStack,
  Spinner,
  StackProps,
} from "@chakra-ui/react";
import { QueueStatus, useFeathersService } from "@cx/feathers-client";
import { waitAtLeast } from "@cx/sleep";
import { useAsync } from "react-use";
import { QueueStatusBadge } from "./QueueStatusBadge";

const displayNames = {
  convert: "Doc Converter",
  thumbnail: "Thumbnail",
  "job-application": "Application Relay",
  webhook: "Webhooks",
};

export const QueueStatusList = forwardRef<StackProps, "div">((props, ref) => {
  const service = useFeathersService("queue-status");

  const {
    loading,
    error,
    value: statuses,
  } = useAsync(() => {
    return waitAtLeast(() => service.find() as Promise<QueueStatus[]>, 250);
  });

  if (loading) {
    return (
      <Center minH="7">
        <Spinner size="md" />
      </Center>
    );
  }

  if (error || !statuses) {
    return (
      <Alert {...props} status="warning">
        <AlertIcon />
        Unable to get queue status. Please try again later
      </Alert>
    );
  }

  return (
    <HStack ref={ref} {...props} spacing="4">
      {statuses.map((status) => (
        <QueueStatusBadge
          key={`${status.name}-badge`}
          {...status}
          display={displayNames[status.name as keyof typeof displayNames]}
        />
      ))}
    </HStack>
  );
});
