import { Container } from "@chakra-ui/react";
import { ToggleButton, useVerticalNavbar, VerticalNavBar } from "@cx/ui-common";
import { ErrorBoundedOutlet } from "@cx/ui-features";
import { useRouteGuard } from "@cx/ui-hooks";
import { selectIsAuthenticated } from "@cx/ui-state";
import { FC } from "react";
import { RegionSelector } from "~/src/feature/RegionSelector";
import { navbarItems } from "../navbar-items";

export const DashboardLayout: FC = () => {
  const isAllowed = useRouteGuard("/", selectIsAuthenticated);
  const { colorToggleText, expanded, getButtonProps, transitionProps, width } =
    useVerticalNavbar("Light", "Dark");

  if (!isAllowed) {
    return null;
  }

  return (
    <>
      <VerticalNavBar
        labels={{
          signOut: "Sign Out",
          colorToggle: colorToggleText,
        }}
        expanded={expanded}
        navItems={navbarItems}
      >
        <ToggleButton expanded={expanded} {...getButtonProps()} />
      </VerticalNavBar>

      <RegionSelector />

      <Container maxW="95%" pl={width} mb="8" {...transitionProps}>
        <ErrorBoundedOutlet />
      </Container>
    </>
  );
};
