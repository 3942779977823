import { EntitySpinner } from "@cx/ui-common";
import { JobApplicationView, RedactableJobApplication } from "@cx/ui-features";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";

export const JobApplicationEdit: FC = () => {
  const { data, isLoading } = useEntityHydrator("job-applications", {
    $populate: ["job"],
  });

  if (isLoading || !data) {
    return <EntitySpinner />;
  }

  return (
    <>
      <PageTitle type="view" />
      <JobApplicationView data={data as unknown as RedactableJobApplication} />
    </>
  );
};
