import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { DiversityTable } from "~/src/feature/Diversity/DiversityTable";

export const DiversityList: FC = () => {
  return (
    <>
      <PageTitle type="list" />
      <DiversityTable />
    </>
  );
};
