import { valuesToOptions } from "@cx/format";
import { Regions } from "@cx/region-config";
import { TableInstanceComponent } from "@cx/ui-data-table";
import { useDropdownProps } from "@cx/ui-form";
import { Select } from "chakra-react-select";
import { PlaceholderWithAll } from "./PlaceholderWithAll";
import { SingleValueWithPlaceholder } from "./SingleValueWithPlaceHolder";

const options = valuesToOptions(Regions, false);

export const RegionFilter: TableInstanceComponent = ({
  instance: { setFilter },
}) => {
  const { styles } = useDropdownProps();

  return (
    <Select
      placeholder="Region"
      options={options}
      onChange={(opt) => {
        const value = opt === null ? undefined : opt.value;
        setFilter("region", value);
      }}
      chakraStyles={styles}
      components={{
        SingleValue: SingleValueWithPlaceholder,
        Placeholder: PlaceholderWithAll,
      }}
      variant="unstyled"
      size="sm"
      isClearable
    />
  );
};
