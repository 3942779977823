import { Spinner } from "@chakra-ui/react";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { UserForm } from "~/src/feature/User/UserForm";

export const UserEdit: FC = () => {
  const { data, isLoading } = useEntityHydrator("users");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <PageTitle type="edit" />
      <UserForm data={data} />
    </>
  );
};
