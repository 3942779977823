import { Badge } from "@chakra-ui/react";
import { FC } from "react";

type StatusColors = {
  [key: string]: string;
};

const statusColors: StatusColors = {
  active: "green",
  draft: "gray",
  default: "red",
};

export const StatusBadge: FC<{ status: string }> = ({ status }) => {
  const color = statusColors[status] ?? statusColors.default;

  return <Badge colorScheme={color}>{status}</Badge>;
};
