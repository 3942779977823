import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { IndustryForm } from "~/src/feature/Industry/IndustryForm";

export const IndustryCreate: FC = () => {
  return (
    <>
      <PageTitle type="create" />
      <IndustryForm />
    </>
  );
};
