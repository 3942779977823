import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { CompanyFormWithPartner } from "~/src/feature/Company";

export const CompanyCreate: FC = () => {
  return (
    <>
      <PageTitle type="create" />
      <CompanyFormWithPartner />
    </>
  );
};
