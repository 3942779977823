import { RouteFactory } from "@cx/ui-common";
import { RecruiterCreate } from "./create";
import { RecruiterEdit } from "./edit";
import { RecruiterList } from "./list";

export const RecruiterRoutes = RouteFactory(
  "recruiters",
  RecruiterList,
  RecruiterEdit,
  RecruiterCreate,
);
