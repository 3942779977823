import { chakra } from "@chakra-ui/react";
import { Diversity, DiversityTypes } from "@cx/feathers-client";
import { valuesToOptions } from "@cx/format";
import { Dropdown, FormInput, FormRow, FormSubmitButtons } from "@cx/ui-form";
import { useOnSubmit } from "@cx/ui-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DiversitySchema } from "./schema";

type Props = {
  data?: Diversity;
};

export const DiversityForm: FC<Props> = ({ data }) => {
  const save = useOnSubmit("diversities", data?._id);

  const methods = useForm<DiversitySchema>({
    resolver: yupResolver(DiversitySchema, { stripUnknown: true }),
    defaultValues: data,
    mode: "all",
  });

  return (
    <FormProvider {...methods}>
      <chakra.form onSubmit={methods.handleSubmit(save)} noValidate>
        <FormRow>
          <FormInput field="label" title="Label" required />
        </FormRow>
        <FormRow>
          <Dropdown
            field="type"
            title="Type"
            options={valuesToOptions(DiversityTypes, false)}
          />
        </FormRow>

        {/* Buttons */}
        <FormSubmitButtons to="/diversities" />
      </chakra.form>
    </FormProvider>
  );
};
