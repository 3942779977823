import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Webhook } from "@cx/feathers-client";
import { toDateString } from "@cx/format";
import { FC } from "react";
import { HttpStatusBadge } from "./HttpStatusBadge";

type Props = {
  data: Webhook;
};

export const WebhookEntity: FC<Props> = ({ data }) => {
  return (
    <TableContainer mb="6">
      <Table
        sx={{
          "td:first-of-type": {
            fontWeight: "bold",
          },
          pre: {
            whiteSpace: "pre-wrap",
          },
        }}
        variant="simple"
      >
        <Thead>
          <Tr>
            <Th w="36">Field</Th>
            <Th w="full">Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>ID</Td>
            <Td>{data._id}</Td>
          </Tr>
          <Tr>
            <Td>Captured At</Td>
            <Td>{toDateString(data.createdAt)}</Td>
          </Tr>
          <Tr>
            <Td>Partner</Td>
            <Td>{data.partner}</Td>
          </Tr>
          <Tr>
            <Td>Event</Td>
            <Td>{data.event}</Td>
          </Tr>
          <Tr>
            <Td>Status</Td>
            <Td>
              <HttpStatusBadge>{data.status}</HttpStatusBadge>
            </Td>
          </Tr>
          <Tr>
            <Td>Payload</Td>
            <Td>
              <pre>{JSON.stringify(data.payload, null, 2)}</pre>
            </Td>
          </Tr>
          <Tr>
            <Td>Error</Td>
            <Td>
              <pre>{JSON.stringify(data.error, null, 2)}</pre>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
